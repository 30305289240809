<template>
  <div>
    <v-container
      fluid
      class="py-4 white--text h4 mb-0 mt-4"
      style="background-color: var(--secondary-color)"
    >
      <v-row>
        <v-col>
          <p v-if="currentRoute === 'Comprador'" class="mt-4 white-text px-4">
            <span v-if="getLenguage === 'es'">
              Noches y/o habitaciones extras corren por cuenta de ustedes y
              deberán solicitarse a
              <a href="mailto:dgarcia@intermeeting.com.mx" class="link">
                dgarcia@intermeeting.com.mx.
              </a>
            </span>
            <span v-else>
              If dou you requiere extra nights, please contact to:
              <a href="mailto:dgarcia@intermeeting.com.mx" class="link">
                dgarcia@intermeeting.com.mx.
              </a>
              This cost will come at your own expense.
            </span>
          </p>
          <p v-else class="mt-4 white-text px-4">
            Si alguno de los datos es erróneo o desea realizar un cambio, por
            favor póngase en contacto a los correos
            <a href="mailto:dgarcia@intermeeting.com.mx" class="link">
              dgarcia@intermeeting.com.mx
            </a>
            o
            <a href="mailto:avargas@intermeeting.com.mx" class="link">
              avargas@intermeeting.com.mx
            </a>
            o al teléfono
            <a href="tel:5556630402" class="link">(55)-56630402</a>
          </p>
          <h4 class="h4 white--text font-weight-black">Intermeeting 2023</h4>
        </v-col>
      </v-row>
    </v-container>
    <footer class="ebg-dark-gray ec-white">
      <div class="econtainer epy-1">
        <div class="erow">
          <div class="ecol-xl-4 ecol-mg-12 emb-2-mg">
            <div class="ed-flex-xl emiddle-xl">
              <figure class="text-center">
                <figcaption class="emb-05">
                  <small>{{ $t('footer.organizedby') }}</small>
                </figcaption>
                <br />
                <img
                  src="@/assets/amajal-logo.png"
                  alt="afamjal"
                  class="lazyload"
                  loading="lazy"
                  width="71"
                  height="71"
                />
              </figure>
              <div class="epl-05 ept-1">
                <p>
                  <small class="white-text">
                    Av. Mariano Otero #1313 Col. Rinconada del Bosque,
                    Guadalajara, Jalisco, M&#xE9;xico, C.P. 44530.
                  </small>
                </p>
              </div>
            </div>
            <small>
              <a
                href="mailto:informes@expomuebleinternacional.com.mx"
                class="mt-1 inline-block-xl c-white white-text"
              >
                informes@expomuebleinternacional.com.mx
              </a>
            </small>
            <div class="ed-flex-xl emiddle-xl emt-1">
              <figure class="text-center">
                <img
                  src="@/assets/logoft.png"
                  class="lazyload"
                  loading="lazy"
                  width="71"
                  height="71"
                />
              </figure>
              <div class="ed-flex-xl emiddle-xl">
                <i class="icon-phone-call"></i>
                <p class="eml-05">
                  <small class="white-text">&#x2B;52 333 343 3400</small>
                </p>
              </div>
            </div>
          </div>
          <div class="ecol-xl-2 ecol-mg-4 ecol-md-12 emb-2"></div>
          <div class="ecol-xl-3 ecol-mg-4 ecol-md-12 emb-2 footer-section">
            <h5 class="etitle-small-1 white-text text-left">
              {{ $t('footer.followus') }}
            </h5>

            <div class="emy-05 enav-social ed-flex-xl emiddle-xl">
              <a
                href="https://www.facebook.com/Expomuebleinternacional/"
                target="_blank"
              >
                <i class="icon-facebook"></i>
              </a>
              <a href="https://twitter.com/expomueble" target="_blank">
                <i class="icon-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/expomuebleint/"
                target="_blank"
              >
                <i class="icon-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/expomuebleinternacional/"
                target="_blank"
              >
                <i class="icon-linkedin"></i>
              </a>
              <a href="https://www.youtube.com/user/expomueble" target="_blank">
                <i class="icon-youtube"></i>
              </a>
              <a
                href="https://www.tiktok.com/@expomuebleintoficial"
                target="_blank"
              >
                <i class="icon-tik-tok"></i>
              </a>
            </div>

            <div class="emy-05">
              <h5 class="efsize18 white-text text-left">
                {{ $t('footer.hoursbuyers') }}
              </h5>
              <p class="white-text text-left">10:00 - 20:00 hrs.</p>
            </div>

            <div class="my-05">
              <h5 class="efsize18 white-text text-left">
                {{ $t('footer.hoursexhibitors') }}
              </h5>
              <p class="white-text text-left">09:00 - 20:00 hrs.</p>
            </div>
          </div>
          <div class="ecol-xl-3 ecol-mg-4 ecol-md-12">
            <ul>
              <li class="text-left">
                <a
                  href="http://tecnomueble.com.mx/"
                  target="_blank"
                  class="white-text"
                >
                  - Tecno Mueble Internacional
                </a>
              </li>
              <li class="text-left">
                <a
                  href="https://dimueble.com.mx/"
                  target="_blank"
                  class="white-text"
                >
                  - Dimueble
                </a>
              </li>
              <li class="text-left">
                <a
                  href="https://moblaje.mx/"
                  target="_blank"
                  class="white-text"
                >
                  - Moblaje
                </a>
              </li>
              <li class="text-left">
                <a
                  href="https://afamjal.com.mx/"
                  target="_blank"
                  class="white-text"
                >
                  - Afamjal
                </a>
              </li>
              <li class="text-left">
                <a
                  href="https://cimejal.com.mx/"
                  target="_blank"
                  class="white-text"
                >
                  - Cimejal
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ebg-black">
        <div class="econtainer epy-1 text-center">
          <p class="ebold white-text">
            <a href="/aviso-de-privacidad" class="white-text">
              {{ $t('footer.privacypolicy') }}
            </a>
            -
            <a href="/terminos-y-condiciones" class="white-text">
              {{ $t('footer.termsconditions') }}
            </a>
            | {{ $t('footer.copyright') }}
          </p>
          <p class="eregular emy-1 white-text">
            {{ $t('footer.restrictions') }}
          </p>

          <p>
            <small class="white-text" v-html="$t('footer.restrictionstext')">
            </small>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import i18n from '@/i18n';

export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    getLenguage() {
      return i18n.locale;
    },
  },
};
</script>

<style>
.white-text,
.link {
  color: white !important;
}
</style>
