<template>
  <v-app class="my-app">
    <header-component />
    <router-view :key="$route.path" />
    <footer-component />
  </v-app>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';

import HeaderComponent from './components/HeaderComponent.vue';

export default {
  components: {
    FooterComponent,
    HeaderComponent,
  },
  mounted() {
    this.$store.dispatch('getUser');
  },
  created() {
    if (localStorage.getItem('locale')) {
      this.$i18n.locale = localStorage.getItem('locale');
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('@/assets/css/external.css');
:root {
  --primary-color: #4f2c58;
  --primary-color-hover: #6d4199;
  --secondary-color: #6e59e6;
  --dark-color: #3e3d40;
  --font-size: 1.1rem;
  --border-radious: 15px;
}

#app {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f4f3f3;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: var(--primary-color) !important;
}

p {
  font-family: 'Raleway', sans-serif;
  color: var(--dark-color);
}
.star {
  color: #ffcc00;
}

a.material,
button.material {
  background-color: var(--primary-color);
  color: #ffffff;
  display: inline-block;
  margin: auto;
  height: 28px;
  width: 110px;
  line-height: 28px;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border: none;
  text-align: center;
  font-size: 11px;
  margin-bottom: 10px;
}

a.material:hover,
button.material:hover {
  background-color: var(--primary-color);
  color: #ffffff;
  box-shadow: 1px 6px 15px rgba(0, 0, 0, 0.5);
}

.my-app.v-application .primary--text {
  color: var(--primary-color) !important;
  caret-color: var(--primary-color) !important;
}
p {
  font-family: 'Raleway', sans-serif;
  color: var(--dark-color);
  font-size: var(--font-size);
}
h1 {
  font-size: 42px !important;
  line-height: 42px !important;
}
h2 {
  font-size: 26px !important;
  line-height: 26px !important;
}

td,
th,
.v-list--dense .v-list-item .v-list-item__title {
  font-size: var(--font-size) !important;
}
.theme--dark.v-btn.v-btn--has-bg:hover {
  background-color: var(--primary-color-hover) !important;
  color: white !important;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--primary-color-hover) !important;
  color: white !important;
}
.v-date-picker-table .v-btn.v-btn--active {
  background-color: var(--primary-color) !important;
}
.grecaptcha-badge {
  z-index: 999;
}
</style>
