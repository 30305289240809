<template>
  <div>
    <map-component />
  </div>
</template>

<script>
import MapComponent from '@/components/MapComponent.vue';
export default {
  components: { MapComponent },
};
</script>

<style></style>
