import auth from '@/api/auth';
import Vue from 'vue';
import Vuex from 'vuex';
// import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: {
      id: null,
      email: null,
      name: null,
    },
  },
  getters: {},
  mutations: {
    setUser(state, user) {
      state.user.id = user.id;
      state.user.email = user.email;
      state.user.name = user.name;
    },
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
  },
  actions: {
    getUser({ commit }) {
      auth.csrftoken().then(() => {
        auth
          .getUser()
          .then((response) => {
            const { data } = response;
            if (data.id) {
              commit('setUser', data);
              commit('setIsLoggedIn', true);
            }
          })
          .catch(() => console.log('Not logged in'));
      });
    },
  },
  modules: {},
});
