<template>
  <header class="">
    <div class="ebg-primary epy-05-mg enav-top">
      <div class="econtainer1600">
        <div class="erow ebetween-xl">
          <div class="ed-flex-xl eend-xl ebetween-xs epl-1 ecol-xs-12">
            <div class="enav-contact ed-flex-xl emiddle-xl">
              <a href="tel://+523333433400" class="ebg-header-contact">
                <i class="icon-phone-call"></i>
                <span class="eml-05 none991"> +52 333 343 3400 </span>
              </a>
            </div>
            <div class="enav-social ed-flex-xl emiddle-xl eml-05">
              <a
                href="https://www.facebook.com/Expomuebleinternacional/"
                target="_blank"
              >
                <i class="icon-facebook"></i>
              </a>
              <a href="https://twitter.com/expomueble" target="_blank">
                <i class="icon-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/expomuebleint/"
                target="_blank"
              >
                <i class="icon-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/expomuebleinternacional/"
                target="_blank"
              >
                <i class="icon-linkedin"></i>
              </a>
              <a href="https://www.youtube.com/user/expomueble" target="_blank">
                <i class="icon-youtube"></i>
              </a>
              <a
                href="https://www.tiktok.com/@expomuebleintoficial"
                target="_blank"
              >
                <i class="icon-tik-tok"></i>
              </a>
            </div>
          </div>
          <div
            v-if="getLenguage === 'en'"
            class="ed-flex-xl eend-xl epr-1 none480"
          >
            <a
              href="https://exmi.apptl.mx/"
              target="_blank"
              class="ebtn ebtn-secondary ebtn-square"
            >
              REGISTRY
            </a>
            <a
              @click.prevent="selLocale('es')"
              class="ebtn ebtn-primary ebtn-square"
            >
              Spanish
            </a>
          </div>
          <div v-else class="d-flex-xl end-xl pr-1">
            <a
              href="https://exmi.apptl.mx/"
              target="_blank"
              class="ebtn ebtn-secondary ebtn-square"
            >
              REGISTRO
            </a>
            <a
              @click.prevent="selLocale('en')"
              class="ebtn ebtn-primary ebtn-square none480"
            >
              Inglés
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="econtainer1600">
      <div class="erow ebetween-xl emiddle-xl epy-1">
        <div class="ed-flex-mg ebetween-xl emiddle-xl epx-1 ecol-mg-12">
          <figure class="elogo epr-1">
            <a
              onclick="this.classList.toggle('running')"
              class="ld-over-full"
              :href="home[$i18n.locale]"
            >
              <img
                :src="logo"
                alt="Expo Mueble Internacional"
                width="365"
                height="64"
              />
            </a>
          </figure>
          <div class="epl-1 ed-flex-xl eend-xl emiddle-xl eflex-wrap-md">
            <a
              href="https://exmi.apptl.mx/"
              target="_blank"
              class="ebtn-small ebtn-secondary ebtn-square show480"
              style="visibility: hidden"
            >
              Regístrate aquí
            </a>
            <a
              v-if="getLenguage === 'en'"
              @click.prevent="selLocale('es')"
              class="ebtn-small ebtn-primary ebtn-square show480"
            >
              Spanish
            </a>
            <a
              v-else
              @click.prevent="selLocale('en')"
              class="ebtn-small ebtn-primary ebtn-square show480"
            >
              Inglés
            </a>
            <button type="button" class="navbar-toggler eml-05-mg">
              <i class="icon-menu"></i>
            </button>
          </div>
        </div>
        <div class="navigation epl-0 ecol-mg-12">
          <nav class="navbar no-mm">
            <div class="navbar-collapse">
              <ul class="navbar-nav">
                <!-- Acerca de -->
                <li class="single-menu">
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full menu-link text-left"
                    :href="menu.about.url[$i18n.locale]"
                  >
                    {{ menu.about.title[$i18n.locale] }}
                    <i class="icon-arrow-light-bottom drop-icon"></i>
                  </a>
                  <div class="sub-menu" style="display: none">
                    <ul>
                      <li
                        v-for="(submenu, index) in menu.about.submenu"
                        :key="index"
                      >
                        <a
                          class="ld-over-full"
                          :href="submenu.url[$i18n.locale]"
                        >
                          {{ submenu.title[$i18n.locale] }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Compradores -->
                <li class="single-menu">
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full menu-link text-left"
                    :href="menu.buyers.url[$i18n.locale]"
                  >
                    {{ menu.buyers.title[$i18n.locale] }}
                    <i class="icon-arrow-light-bottom drop-icon"></i>
                  </a>
                  <div class="sub-menu" style="display: none">
                    <ul>
                      <li
                        v-for="(submenu, index) in menu.buyers.submenu"
                        :key="index"
                      >
                        <a
                          class="ld-over-full"
                          :href="submenu.url[$i18n.locale]"
                        >
                          {{ submenu.title[$i18n.locale] }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Expositores -->
                <li class="single-menu">
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full menu-link text-left"
                    :href="menu.exhibitors.url[$i18n.locale]"
                  >
                    {{ menu.exhibitors.title[$i18n.locale] }}
                    <i class="icon-arrow-light-bottom drop-icon"></i
                  ></a>
                  <div class="sub-menu" style="display: none">
                    <ul>
                      <li
                        v-for="(submenu, index) in menu.exhibitors.submenu"
                        :key="index"
                      >
                        <a
                          class="ld-over-full"
                          :href="submenu.url[$i18n.locale]"
                        >
                          {{ submenu.title[$i18n.locale] }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Events -->
                <!-- <li>
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full text-left"
                    :href="menu.events.url[$i18n.locale]"
                  >
                    {{ menu.events.title[$i18n.locale] }}
                  </a>
                </li> -->
                <!-- Maps -->
                <li>
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full text-left"
                    :href="menu.maps.url[$i18n.locale]"
                  >
                    {{ menu.maps.title[$i18n.locale] }}
                  </a>
                </li>
                <!-- Virtual Catalo -->
                <li>
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full text-left"
                    :href="menu.vcatalog.url[$i18n.locale]"
                  >
                    {{ menu.vcatalog.title[$i18n.locale] }}
                  </a>
                </li>
                <!-- Blog -->
                <li>
                  <a
                    onclick="this.classList.toggle('running')"
                    class="ld-over-full text-left"
                    :href="menu.blog.url[$i18n.locale]"
                  >
                    {{ menu.blog.title[$i18n.locale] }}
                  </a>
                </li>
                <li>
                  <a
                    @click="$event.target.classList.toggle('running')"
                    class="ld-over-full text-left"
                    :href="menu.contact.url[$i18n.locale]"
                  >
                    {{ menu.contact.title[$i18n.locale] }}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logo from '@/assets/logo.png';
export default {
  methods: {
    selLocale(locale) {
      localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;
    },
  },
  data: () => ({
    logo,
    home: {
      es: 'https://expomuebleinternacional.com.mx/',
      en: 'https://expomuebleinternacional.com.mx/en',
    },
    menu: {
      about: {
        title: {
          es: 'Acerca de',
          en: 'About',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/acerca-de',
          en: 'https://expomuebleinternacional.com.mx/en/about',
        },
        submenu: [
          {
            title: {
              es: 'Media Kit',
              en: 'Media Kit',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/acerca-de#media-kit',
              en: 'https://expomuebleinternacional.com.mx/en/about#media-kit',
            },
          },
        ],
      },
      buyers: {
        title: {
          es: 'Compradores',
          en: 'Buyers',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/compradores',
          en: 'https://expomuebleinternacional.com.mx/en/buyers',
        },
        submenu: [
          {
            title: {
              es: 'Beneficios',
              en: 'Proceeds',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/compradores#beneficios',
              en: 'https://expomuebleinternacional.com.mx/en/buyers#proceeds',
            },
          },
          {
            title: {
              es: 'Testimoniales',
              en: 'Testimonials',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/compradores#testimoniales',
              en: 'https://expomuebleinternacional.com.mx/en/buyers#testimonials',
            },
          },
          {
            title: {
              es: 'Hospedaje',
              en: 'Lodging',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/compradores#hospedaje',
              en: 'https://expomuebleinternacional.com.mx/en/buyers#lodging',
            },
          },
        ],
      },
      exhibitors: {
        title: {
          es: 'Expositores',
          en: 'Exhibitors',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/expositores',
          en: 'https://expomuebleinternacional.com.mx/en/exhibitors',
        },
        submenu: [
          {
            title: {
              es: 'Beneficios',
              en: 'Proceeds',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/expositores#beneficios',
              en: 'https://expomuebleinternacional.com.mx/en/exhibitors#proceeds',
            },
          },
          {
            title: {
              es: 'Solicitud de stand',
              en: 'Booth form',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/expositores#solicitud_stand',
              en: 'https://expomuebleinternacional.com.mx/en/exhibitors#booth',
            },
          },
          {
            title: {
              es: 'Contacta a un Ejecutivo',
              en: 'Contact an Executive',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/expositores#contacta',
              en: 'https://expomuebleinternacional.com.mx/en/exhibitors#contact',
            },
          },
          {
            title: {
              es: 'Media Tool Kit',
              en: 'Media Tool Kit',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/media-tool-kit',
              en: 'https://expomuebleinternacional.com.mx/en/media-tool-kit',
            },
          },
          {
            title: {
              es: 'Promoción y patrocinio',
              en: 'Promotion & sponsorship',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/promocion-y-patrocinio',
              en: 'https://expomuebleinternacional.com.mx/en/promotion-and-sponsorship',
            },
          },
          {
            title: {
              es: 'Manual del expositor',
              en: 'Exhibitor manual',
            },
            url: {
              es: 'https://expomuebleinternacional.com.mx/promocion-y-patrocinio/#manual-expositor',
              en: 'https://expomuebleinternacional.com.mx/en/promotion-and-sponsorship/#exhibitor-manual',
            },
          },
        ],
      },
      events: {
        title: {
          es: 'Eventos',
          en: 'Events',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/eventos',
          en: 'https://expomuebleinternacional.com.mx/en/events',
        },
      },
      maps: {
        title: {
          es: 'Planos',
          en: 'Maps',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/planos',
          en: 'https://expomuebleinternacional.com.mx/en/maps',
        },
      },
      vcatalog: {
        title: {
          es: 'Catálogo virtual',
          en: 'Virtual catalog',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/catalogo-virtual',
          en: 'https://expomuebleinternacional.com.mx/en/virtual-catalog',
        },
      },
      blog: {
        title: {
          es: 'Blog',
          en: 'Blog',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/blog',
          en: 'https://expomuebleinternacional.com.mx/en/blog',
        },
      },
      contact: {
        title: {
          es: 'Contacto',
          en: 'Contact',
        },
        url: {
          es: 'https://expomuebleinternacional.com.mx/contacto',
          en: 'https://expomuebleinternacional.com.mx/en/contact',
        },
      },
    },
  }),
  computed: {
    getLenguage() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0;
}

.sub-menu {
  text-align: left;
}

.sub-menu ul li a {
  color: black !important;
}
.sub-menu ul li a:hover {
  color: white !important;
}
</style>
