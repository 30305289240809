import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/reservar/:id',
    name: 'reservar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Booking" */ '../views/BookingView.vue'),
  },
  {
    path: '/comprador/:language',
    name: 'Comprador',
    component: () =>
      import(/* webpackChunkName: "Buyer" */ '../views/BuyerView.vue'),
  },
  {
    path: '/confirmar/:email/:entrada?/:salida?',
    name: 'confirmar',
    component: () =>
      import(/* webpackChunkName: "Booking" */ '../views/ConfirmationView.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "Admin" */ '../views/AdminView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
