import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4f2c58',
        'primary-light': '#6e59e6',
        secondary: '#6e59e6',
      },
    },
  },
});
