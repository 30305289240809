import axios from 'axios';
axios.defaults.withCredentials = true;
import store from '@/store/index';

const BASE_URL = process.env.VUE_APP_BASE_URL;

export default {
  login(payload) {
    return axios.post(`${BASE_URL}login`, payload);
  },
  async logout() {
    const response = await axios.post(`${BASE_URL}logout`);
    if (response.data === true) {
      const user = {
        id: null,
        email: null,
        name: null,
      };
      store.commit('setUser', user);
      store.commit('setIsLoggedIn', false);
    }
  },
  csrftoken() {
    return axios.get(`${BASE_URL}sanctum/csrf-cookie`, {
      withCredentials: true,
    });
  },
  getUser() {
    return axios.get(`${BASE_URL}user`);
  },
};
