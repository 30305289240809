import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import * as VueGoogleMaps from 'vue2-google-maps';
import i18n from './i18n';
import Vue2Filters from 'vue2-filters';
import store from './store';

Vue.use(Vue2Filters);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC_uum5JpQR4R0Np7ae4xShcUm3X2lZeLQ',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

Vue.config.productionTip = false;

// Importamos JQuery
const $ = require('jquery');
// Lo declaramos globalmente
window.$ = $;

// MENÃš RESPONSIVE
$(function () {
  $('.navbar-toggler').on('click', function () {
    $('.navbar-collapse').slideToggle(300);
  });
  if ($(window).innerWidth() <= 992) {
    $('.btn-ancla').on('click', function () {
      $('.navbar-collapse').slideUp(300);
    });
  }

  smallScreenMenu();
  let temp;
  function resizeEnd() {
    smallScreenMenu();
  }

  $(window).on('resize', function () {
    clearTimeout(temp);
    temp = setTimeout(resizeEnd, 100);
    resetMenu();
  });
});

const subMenus = $('.sub-menu');
const menuLinks = $('.menu-link');

function smallScreenMenu() {
  if ($(window).innerWidth() <= 992) {
    // eslint-disable-next-line
    menuLinks.each(function (item) {
      $(this).on('click', function () {
        $(this).next().slideToggle();
      });
    });
  } else {
    // eslint-disable-next-line
    menuLinks.each(function (item) {
      $(this).off('click');
    });
  }
}

function resetMenu() {
  if ($(window).innerWidth() > 992) {
    // eslint-disable-next-line
    subMenus.each(function (item) {
      $(this).css('display', 'none');
    });
  }
}
// HEADER STICKY
$(window).on('scroll', function () {
  if ($(window).width() <= 480) {
    if ($(window).scrollTop() >= 10) {
      $('header').addClass('fixed-header');
      $('.hide-menu').addClass('hide-menu-fixed');
    } else {
      $('header').removeClass('fixed-header');
      $('.hide-menu').removeClass('hide-menu-fixed');
    }
  } else if ($(window).width() <= 736) {
    if ($(window).scrollTop() >= 134) {
      $('header').addClass('fixed-header');
      $('.hide-menu').addClass('hide-menu-fixed');
    } else {
      $('header').removeClass('fixed-header');
      $('.hide-menu').removeClass('hide-menu-fixed');
    }
  } else {
    if ($(window).scrollTop() >= 154) {
      $('header').addClass('fixed-header');
      $('.hide-menu').addClass('hide-menu-fixed');
    } else {
      $('header').removeClass('fixed-header');
      $('.hide-menu').removeClass('hide-menu-fixed');
    }
  }
});

// FOOTER
$(window).bind('load', function () {
  // eslint-disable-next-line
  var footerHeight = 0,
    // eslint-disable-next-line
    footerTop = 0,
    $footer = $('footer');

  positionFooter();
  function positionFooter() {
    footerHeight = $footer.height();
    footerTop = $(window).height() + 'px';
    if ($(document.body).height() < $(window).height()) {
      $footer
        .css({
          position: 'absolute',
        })
        .animate(
          {
            bottom: 0,
          },
          -1
        );
    } else {
      $footer.css({
        position: 'static',
      });
    }
  }
  $(window).on('scroll', positionFooter).on('resize', positionFooter);
});

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app');
