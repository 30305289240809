const hoteles = [
  {
    id: 1,
    zone: 1,
    name: {
      es: 'Barceló Guadalajara',
      en: 'BARCELO GUADALAJARA',
    },
    stars: 5,
    info: {
      es: 'Barceló Guadalajara se encuentra en Zona Expo, una zona con acceso fácil al centro de convenciones. Teatro Degollado y Auditorio Telmex son lugares culturales destacados, y algunos de los puntos de interés más importantes del área incluyen La Minerva y Instituto Cultural Cabañas. A los huéspedes les encanta la ubicación céntrica de este hotel.',
      en: 'Barcelo Guadalajara is in the expo zone, easy access to the convention center. Teatro Delgadillo and Auditorio Telmex are cultural highlights of the area and include La Minerva and Instituto cultural Cabañas. Most of the guests love the ubication of the hotel.',
    },
    address: 'Avenida De Las Rosas 2933, Guadalajara, JAL, 44540',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.653536,
      lng: -103.389725,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'No incluye',
          price: '$1,390',
        },
        {
          name: 'Doble',
          breakfast: 'No incluye',
          price: '$1,390',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Not included',
          price: '$1,390',
        },
        {
          name: 'Double',
          breakfast: 'Not included',
          price: '$1,390',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
        <li>Favor de dar <a href="https://intermeetingonline.com.mx/hospedaje/expomueble24/">"Click aquí" para que pueda realizar la reservación correspondiente.</a></li>
        <li>Una vez que ingrese sus datos el banco realizará el cargo por el total de las noches reservadas.</li>
        <li>Recibirá la confirmación a su correo que ingreso en su registro una vez que el banco autorice el cargo.</li>
      </ul>
    `,
      en: `
    <ul>
      <li>Please <a href="https://intermeetingonline.com.mx/hospedaje/expomueble24/index.php?idioma=en">click here</a>.</li>
      <li>Once you enter your information, the bank will charge you the total room nights reserved.</li>
      <li>You will receive the confirmation to the email that you entered in your registry once the bank authorizes the charge.</li>
    </ul>
    `,
    },
    link: '',
    terms_conditions: {
      es: `
      <ul>
        <li>Las tarifas preferenciales incluyen impuestos y propinas (camaristas y botones).</li>
        <li>La tarifa preferencial estará disponible hasta agotar existencias.</li>
        <li>Cambios y cancelaciones sin cargo antes del 15 de enero 2024: después de esta fecha aplicará el cargo por el total de la estancia.</li>
        <li>En caso de no presentarse se aplicará el cargo por el TOTAL de la estancia reservada.</li>
        <li>Los cargos por salida anticipada se aplicará el cargo por el TOTAL de la estancia reservada.</li>
        <li>Cualquier cambio o cancelación deberá ser notificada por escrito vía correo electrónico: <a href="mailto:dgarcia@intermeeting.com.mx">dgarcia@intermeeting.com.mx</a>. No se aceptan cancelaciones vía telefónica ni directamente con el hotel.</li>
        <li>Todos los cambios están sujetos a disponibilidad.</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Preferential rates include taxes and tips (waitresses and bellboys).</li>
        <li>The preferential rate will be available while supplies last.</li>
        <li>Changes and cancellations without charge before January 15th. After this date the charge for the total booking will apply.</li>
        <li>In case of no-show, the charge will be applied for the TOTAL of the booking.</li>
        <li>Early departure charges will be charged for the TOTAL booking.</li>
        <li>Any change or cancellation must be notified in writing via email: <a href="mailto:dgarcia@intermeeting.com.mx">dgarcia@intermeeting.com.mx</a></li>
        <li>Cancellations are not accepted by telephone or directly with the hotel.</li>
        <li>All changes are subject to availability.</li>
      </ul>
      `,
    },
  },
  {
    id: 2,
    zone: 1,
    name: {
      es: 'Fiesta Inn Guadalajara Expo',
      en: 'FIESTA INN GUADALAJARA EXPO',
    },
    stars: 4,
    info: {
      es: 'Fiesta Inn Guadalajara Expo se encuentra en una zona comercial, en el área de Zona Expo en Zapopan. Basílica de Zapopan es un lugar emblemático, y los turistas que quieran ir de compras pueden visitar Plaza del Sol y Avenida Chapultepec.',
      en: 'Fiesta Inn Guadalajara Expo is located in the Expo Zapopan Zone. The Basilica of Zapopan it’s an emblematic place, and the tourists that go shopping can visit Plaza del Sol and Avenida Chapultepec.',
    },
    address:
      'Av. Mariano Otero 1550, Col. Rinconada del Sol, Zapopan, JAL, 45055',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.65239,
      lng: -103.396545,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'No incluye',
          price: '$1,600',
        },
        {
          name: 'Doble',
          breakfast: 'No incluye',
          price: '$1,600',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Not included',
          price: '$1,600',
        },
        {
          name: 'Double',
          breakfast: 'Not included',
          price: '$1,600',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
      <li>Llamar a la central de reservaciones al número 55 5326 69 00 o del Interior de la República 800 50 450 00</li>
      <li>Mencionar que cuenta con el código: <b>EXPO MUEBLES</b> para obtener la tarifa preferencial</li>
      <li>Dar sus preferencias de estancia a la operadora</li>
      <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
      <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Call the phone number 55 5326 6300 or other areas of Mexico 800 5045 000</li>
        <li>Mention the Fare code: <b>EXPO MUEBLES</b></li>
        <li>Give your stay preferences to the operator.</li>
        <li>You´ll receive an email with your reservation confirmation.</li>
        <li>Settle the payment at your arrival.</li>
      </ul>
      `,
    },
    link: '',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta agotar exigencias</li>
        <li>Las políticas de cancelación de habitaciones, no llegada o salida anticipada serán informadas a los participantes al momento de realizar su reservación y aplicarán de acuerdo a la política de central de reservaciones.</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Cancellations, early departure or not coming will be notified to the hotel personal and will apply to reservation central politics.</li>
      </ul>
      `,
    },
  },
  {
    id: 4,
    zone: 1,
    name: {
      es: 'Real Inn Expo',
      en: 'REAL INN EXPO',
    },
    stars: 4,
    info: {
      es: 'Real Inn Guadalajara Expo cuenta con una ubicación cercana a Expo Guadalajara y Plaza del Sol, ofreciéndote cafetería, servicio de lavandería o tintorería y bar. Prueba el menú de Stock Cafe, su restaurante. Hay wifi gratis en las habitaciones disponible, además de gimnasio y centro de negocios.',
      en: 'City Plus Guadalajara Expo has a near ubication at the Expo Guadalajara and Plaza del Sol, offering continental Breakfast included, cafeteria and laundry service or dry cleaner. Free WIFI, GYM and business center.',
    },
    address:
      'Av Mariano Otero 1326, Esq De las Rosas, Col. Jardines de San Ignacio, Guadalajara, JAL, 45040',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.655611,
      lng: -103.3936567,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'No Incluye',
          price: '$1,900',
        },
        {
          name: 'Doble',
          breakfast: 'No Incluye',
          price: '$1,900',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Not included',
          price: '$1,900',
        },
        {
          name: 'Double',
          breakfast: 'Not included',
          price: '$1,900',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
        <li>Dar click en el botón de “reservar”.</li>
        <li>Sera direccionado a la pag web de reservas de Real Inn</li>
        <li>Seleccionar la habitación de su preferencia, esta mostrará la tarifa preferencia del evento</li>
        <li>Llenar los datos requeridos para personalizar su reserva</li>
        <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
        <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Click on the “booking” button.</li>
        <li>It will direct you to Real Inn booking.</li>
        <li>Fill the required information for your registration</li>
        <li>You´ll receive an email with your reservation confirmation.</li>
        <li>Settle the payment at your arrival.</li>
      </ul>
      `,
    },
    link: 'https://bit.ly/43VroUG',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta agotar existencias</li>
        <li>Cancelación 24 a 48 horas hábiles antes de la llegada.</li>
        <li>En caso de no presentarse se aplicará el cargo por 1 noche de hospedaje.</li>
        <li>No habrá cargos por salida anticipada.</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Changes, Cancellation can be done by 24 to 48 hrs before the arrival date.</li>
        <li>Early departure will not be extra charges.</li>
      </ul>
      `,
    },
  },
  {
    id: 5,
    zone: 1,
    name: {
      es: 'Westin Guadalajara',
      en: 'WESTIN GUADALAJARA',
    },
    stars: 5,
    info: {
      es: 'A corta distancia de Expo Guadalajara y Plaza del Sol se encuentra The Westin Guadalajara, que te ofrece terraza, cafetería y servicio de lavandería o tintorería. Prueba el menú de El Rosedal, su restaurante, que ofrece cocina internacional. Podrás conectarte al wifi en las habitaciones (aplican cargos) y encontrarás diversos servicios, como bar y gimnasio abierto las 24 horas.',
      en: 'It is a short distance from Expo Guadalajara and Plaza del Sol the Westin Guadalajara, that offers rooftop, cafeteria and laundry service or dry cleaner, GYM and business center. Wi-Fi in the guests rooms has an extra charge. Try the Menu from El Rosedal, their restaurant has international food.',
    },
    address: 'Avenida de Las Rosas 2911, Guadalajara, JAL, 44530',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.653135,
      lng: -103.389374,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'No Incluye',
          price: '$2,500',
        },
        {
          name: 'Doble',
          breakfast: 'No Incluye',
          price: '$2,500',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Not included',
          price: '$2,500',
        },
        {
          name: 'Double',
          breakfast: 'Not included',
          price: '$2,500',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
        <li>Dar click en el botón de “reservar”. Será redirigido a la página web del hotel.</li>
        <li>Seleccionar la habitación de su preferencia, esta mostrará la tarifa preferencia del evento</li>
        <li>Llenar los datos requeridos para personalizar su reserva</li>
        <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
        <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Click on the “booking” button. It will direct you to the hotel website.</li>
        <li>Select your preference room, this will show you the best fare price for the event.</li>
        <li>Fill the required information for your registration.</li>
        <li>Settle the payment at your arrival</li>
      </ul>
      `,
    },
    link: 'https://www.marriott.com/event-reservations/reservation-link.mi?id=1702079948062&key=GRP&app=resvlink',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta agotar existencias</li>
        <li>Cambios y cancelaciones son permitidos hasta con 48 hrs. Antes de su llegada.</li>
        <li>En caso de no presentarse se aplicará el cargo por 1 noche de estancia.</li>
        <li>Los cargos por salida anticipada se aplicará el cargo por el TOTAL de la estancia reservada.</li>
        <li>En caso de cancelaciones le sugerimos conservar el número de cancelación para cualquier aclaración posterior.</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Cancellations and changes can be applied without any extra charge before 48hrs before the arrival date, after this date a charge for the entire date will be applied.</li>
        <li>In case of booking the day and don’t come, it will be a charge for 1 extra night plus taxes.</li>
        <li>Charges for early departure will be applied to the total charge of the reserved days.</li>
        <li>In case of cancellation, we suggest you have the cancellation number to clarify any problem.</li>
      </ul>
      `,
    },
  },
  {
    id: 7,
    zone: 2,
    name: {
      es: 'Hampton Inn Guadalajara Expo',
      en: 'HAMPTON INN GUADALAJARA EXPO',
    },
    stars: 4,
    info: {
      es: 'Con una ubicación cercana a Expo Guadalajara y Plaza del Sol, Hampton Inn by Hilton Guadalajara/Expo te ofrece desayuno buffet incluido, terraza y cafetería. Podrás conectarte al wifi gratis en las habitaciones y encontrarás diversos servicios, como servicio de lavandería o tintorería y bar.',
      en: 'Hampton Inn by Hilton Guadalajara Expo is ubicated near Plaza del Sol and Expo Guadalajara, offers buffet breakfast included, rooftop and cafeteria. Free WIFI and also laundry, dry clean and a bar.',
    },
    address: 'Avenida De las Rosas 3030, Guadalajara, JAL, 44500',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.659313,
      lng: -103.396873,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'Buffet',
          price: '$1,650',
        },
        {
          name: 'Doble',
          breakfast: 'Buffet',
          price: '$1,650',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Buffet',
          price: '$1,650',
        },
        {
          name: 'Double',
          breakfast: 'Buffet',
          price: '$1,650',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
        <li>Dar click en el botón de “reservar”. Será redirigido a la página web del hotel.</li>
        <li>Seleccionar la habitación de su preferencia, esta mostrará la tarifa preferencia del evento</li>
        <li>Llenar los datos requeridos para personalizar su reserva</li>
        <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
        <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Click on the “booking” button. It will direct you to the hotel website.</li>
        <li>Select your preference room, this will show you the best fare price for the event.</li>
        <li>Fill the required information for your registration.</li>
        <li>Settle the payment at your arrival</li>
      </ul>
      `,
    },
    link: 'https://www.hilton.com/es/book/reservation/rooms/?ctyhocn=GDLEXHX&arrivalDate=2024-02-14&departureDate=2024-02-17&groupCode=EMA&room1NumAdults=1&cid=OM%2CWW%2CHILTONLINK%2CEN%2CDirectLink',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta agotar existencias</li>
        <li>Cambios y cancelaciones sin cargo 72 hrs. Antes de la fecha de su llegada</li>
        <li>En caso de no presentarse se aplicará el cargo por 1 noche de hospedaje.</li>
        <li>Los cargos por salida anticipada se aplicará el cargo por 1 noche de estancia.</li>
        <li>En caso de cancelaciones le sugerimos conservar el número de cancelación para cualquier aclaración posterior.</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Cancellations and changes can be applied without any extra charge before 72hrs before the arrival date, after this date a charge for the entire date will be applied.</li>
        <li>In case of booking the day and don’t come, it will be a charge for 1 extra night plus taxes.</li>
        <li>Charges for early departure will be applied to the total charge of the reserved days.</li>
        <li>In case of cancellation, we suggest you have the cancellation number to clarify any problem</li>
      </ul>
      `,
    },
  },
  {
    id: 10,
    zone: 2,
    name: {
      es: 'One Guadalajara Expo',
      en: 'ONE GUADALAJARA EXPO',
    },
    stars: 3,
    info: {
      es: 'A corta distancia de Expo Guadalajara y Plaza del Sol se encuentra One Guadalajara Expo, que te ofrece desayuno buffet incluido, terraza y lavandería. Además de contar con servicios como centro de negocios abierto las 24 horas, podrás conectarte al wifi gratis en las habitaciones.',
      en: 'One Guadalajara Expo is ubicated near Plaza del Sol and Expo Guadalajara, offers buffet breakfast included, rooftop, free WIFI and also laundry, dry clean and business center 24hrs.',
    },
    address:
      'Av. Chapalita #1470 entre, Av. Las Rosas y Av. Lopez Mateos Sur, Guadalajara, JAL, 44500',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.6591,
      lng: -103.397247,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'Americano',
          price: '$1,400',
        },
        {
          name: 'Doble',
          breakfast: 'Americano',
          price: '$1,400',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'American',
          price: '$1,400',
        },
        {
          name: 'Double',
          breakfast: 'American',
          price: '$1,400',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
      <li>Llamar a la central de reservaciones al número 55 5326 69 00 o del Interior de la República 800 50 450 00</li>
      <li>Mencionar que cuenta con el código: <b>EXPO MUEBLES</b> para obtener la tarifa preferencial</li>
      <li>Dar sus preferencias de estancia a la operadora</li>
      <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
      <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Call the phone number 55 5326 69 00 or other areas of Mexico 800 50 450 00</li>
        <li>-	Mention the Fare code: <b>EXPO MUEBLES</b></li>
        <li>Give your stay preferences to the operator.</li>
        <li>You´ll receive an email with your reservation confirmation.</li>
        <li>Settle the payment at your arrival.</li>
      </ul>
      `,
    },
    link: '',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta el 12 de agosto o agotar existencia</li>
        <li>Las políticas de cancelación de habitaciones, no llegada o salida anticipada serán informadas a los participantes al momento de realizar su reservación y aplicarán de acuerdo a la política de central de reservaciones</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Cancellations, early departure or not coming will be notified to the hotel personal and will apply a penalty fee.</li>
      </ul>
      `,
    },
  },
  {
    id: 13,
    zone: 3,
    name: {
      es: 'Fiesta Americana Guadalajara',
      en: 'FIESTA AMERICANA GUADALAJARA',
    },
    stars: 5,
    info: {
      es: 'A corta distancia de La Minerva, Fiesta Americana - Guadalajara te ofrece traslado al aeropuerto (ida y vuelta), tiendas en la propiedad y servicio de lavandería o tintorería. Prueba el menú de La Fronda, su restaurante. Podrás conectarte al wifi gratis en las habitaciones y encontrarás diversos servicios, como bar y gimnasio.',
      en: 'At a short distance from La Minerva, Fiesta Americana- Guadalajara offers roundtrip airport transportation, on-site shopping, and laundry/dry cleaning services. Try the menu at La Fronda, his restaurant. You can connect to the free Wi-Fi in the rooms and you will find various services, such as a bar and a gym.',
    },
    address:
      'Calle Aurelio Aceves 225, Vallarta Poniente, Guadalajara, JAL, 44110',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.677826,
      lng: -103.4040572,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'No Incluye',
          price: '$1,850',
        },
        {
          name: 'Doble',
          breakfast: 'No Incluye',
          price: '$1,850',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Not included',
          price: '$1,850',
        },
        {
          name: 'Double',
          breakfast: 'Not included',
          price: '$1,850',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
        <li>Llamar a la central de reservaciones al número RESERVA AL: 33 3818 1400 o del Interior de la República 44 3310 8006</li>
        <li>Mencionar que cuenta con el código: <b>EXPO MUEBLES</b> para obtener la tarifa preferencial</li>
        <li>Dar sus preferencias de estancia a la operadora</li>
        <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
        <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Call the phone number 55 5326 6300 or other areas of Mexico 800 5045 000</li>
        <li>Mention the Fare code: <b>EXPO MUEBLES</b></li>
        <li>Give your stay preferences to the operator.</li>
        <li>You´ll receive an email with your reservation confirmation.</li>
        <li>Settle the payment at your arrival.</li>
      </ul>
      `,
    },
    link: '',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta el 18 de febrero o agotar existencia</li>
        <li>Las políticas de cancelación de habitaciones, no llegada o salida anticipada serán informadas a los participantes al momento de realizar su reservación y aplicarán de acuerdo a la política de central de reservaciones</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Cancellations, early departure or not coming will be notified to the hotel personal and will apply a penalty fee.</li>
      </ul>
      `,
    },
  },
  {
    id: 15,
    zone: 3,
    name: { es: 'Riu Guadalajara', en: 'RIU GUADALAJARA' },
    stars: 5,
    info: {
      es: 'Hotel Riu Plaza Guadalajara cuenta con una ubicación cercana a La Minerva y Expo Guadalajara, ofreciéndote supermercado o tienda de conveniencia, terraza y cafetería. Regálate un momento de relax con masajes, manicures y pedicures y tratamientos corporales en el spa de las instalaciones, RENOVA. Los dos restaurantes de la propiedad cuentan con cocina fusión y mucho más. Hay wifi gratis en las habitaciones disponible, además de jardín y salón de belleza.',
      en: "Hotel Riu Plaza Guadalajara has a location close to La Minerva and Expo Guadalajara, offering you a supermarket or convenience store, a terrace, and a cafeteria. Treat yourself to a moment of relaxation with massages, manicures and pedicures, and body treatments at the on-site spa, RENOVA. The property's two restaurants feature fusion cuisine and much more. Free in-room WiFi is available, as well as a garden and beauty salon.",
    },
    address: 'Av. Lopez Mateos 830 Col. Chapalita, Guadalajara, JAL, 44500',
    images: ['Imagen 1.jpg', 'Imagen 2.jpg', 'Imagen 3.jpg'],
    position: {
      lat: 20.665842,
      lng: -103.393677,
    },
    rooms: {
      es: [
        {
          name: 'Sencilla',
          breakfast: 'No Incluye',
          price: '$2,100',
        },
        {
          name: 'Doble',
          breakfast: 'No Incluye',
          price: '$2,100',
        },
      ],
      en: [
        {
          name: 'Single',
          breakfast: 'Not included',
          price: '$2,100',
        },
        {
          name: 'Double',
          breakfast: 'Not included',
          price: '$2,100',
        },
      ],
    },
    reservation_method: {
      es: `
      <ul>
        <li>Dar click en el botón de “reservar”. Será redirigido a la página web del hotel.</li>
        <li>Seleccionar el México y Guadalajara como su país y destino</li>
        <li>Elegir las fechas que asistirá durante el evento</li>
        <li>Ingresar el código <b>MUEB24</b> en el apartado “CODIGO CORPORATIVO” para obtener la tarifa preferencial </li>
        <li>Seleccionar la habitación de su preferencia, esta mostrará la tarifa preferencia del evento</li>
        <li>Llenar los datos requeridos para personalizar su reserva</li>
        <li>Recibirá la confirmación de su reserva en el correo que haya proporcionado</li>
        <li>Liquidar su estancia a la llegada al hotel</li>
      </ul>
      `,
      en: `
      <ul>
        <li>Click on the “booking” button. It will direct you to the hotel website.</li>
        <li>Select Mexico and Gualajara as your country and destination</li>
        <li>Insert code <b>MUEB24</b> on corporative code to get the fade price.</li>
        <li>Fill the required information for your registration.</li>
        <li>Settle the payment at your arrival.</li>
      </ul>
      `,
    },
    link: 'https://www.riu.com/consultar-disponibilidad/?corporate',
    terms_conditions: {
      es: `
      <ul>
        <li>Su reservación deberá ser garantizada con tarjeta de crédito.</li>
        <li>La tarifa preferencial estará disponible hasta agotar existencias</li>
        <li>Cambios y cancelaciones sin cargo 48 hrs. Antes de la fecha de su reservación.</li>
        <li>En caso de no presentarse se aplicará el cargo por el TOTAL de la estancia reservada.</li>
        <li>Los cargos por salida anticipada se aplicará el cargo por el TOTAL de la estancia reservada.</li>
        <li>En caso de cancelaciones le sugerimos conservar el número de cancelación para cualquier aclaración posterior.</li>
      </ul>
      `,
      en: `
      <ul>
        <li>The reservation must be guaranteed by a credit card.</li>
        <li>The preference fare will be available while supplies last.</li>
        <li>Cancellations and changes can be applied without any extra charge before 48hrs before the arrival date, after this date a charge for the entire date will be applied.</li>
        <li>In case of booking the day and don’t come, it will be a charge for the total charge of the reserved days.</li>
        <li>Charges for early departure will be applied to the total charge of the reserved days.</li>
        <li>In case of cancellation, we suggest you have the cancellation number to clarify any problem.</li>
      </ul>
      `,
    },
  },
];
export default hoteles;
