<template>
  <v-container class="mt-3 px-4" style="z-index: 1">
    <v-row>
      <v-col class="text-center mt-4">
        <h1 class="text-uppercase mt-4">{{ $t('map.title') }}</h1>
        <p class="mt-3">{{ $t('map.instruction') }}</p>
        <h3 class="contact">
          {{ $t('map.contact1') }}
          <a href="mailto:dgarcia@intermeeting.com.mx">
            dgarcia@intermeeting.com.mx
          </a>
          {{ $t('map.contact2') }}
          <a href="tel:+525556630402">5556630402</a>
        </h3>
        <h2 class="mt-3">{{ $t('map.subtitle') }}</h2>
      </v-col>
    </v-row>
    <v-row class="mb-0 pb-0">
      <v-col cols="12" md="3" offset-md="9" class="mb-0 pb-0 text-left">
        <v-select
          v-model="filter"
          color="primary"
          :items="zones"
          :item-text="$i18n.locale"
          item-value="value"
          :label="$t('map.select')"
          outlined
          dense
          hide-details="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" offset-md="5">
        <v-btn color="primary" dark block @click="toggleMap()">{{
          showMapMessage
        }}</v-btn>
      </v-col>
    </v-row>
    <v-row v-show="showMap" class="mt-0 pt-0">
      <v-col class="mt-0 pt-0">
        <GmapMap
          ref="mapRef"
          :center="{ lat: 20.656351, lng: -103.3889558 }"
          :options="options"
          map-type-id="terrain"
          style="width: 100%; height: 75vh"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <div v-html="infoContent"></div>
          </gmap-info-window>
          <gmap-marker
            :position="hq.position"
            :clickable="true"
            @click="toggleInfoWindow(m, i)"
          ></gmap-marker>
          <gmap-marker
            :key="i"
            v-for="(hotel, i) in filteredHotels"
            :position="hotel.position"
            :clickable="true"
            @click="toggleInfoWindow(hotel, i)"
          ></gmap-marker>
        </GmapMap>
      </v-col>
    </v-row>
    <hotel-list-component :hotels="filteredHotels" :title="zone[0].text" />
  </v-container>
</template>

<script>
import hotels from '@/db.js';
import HotelListComponent from './HotelListComponent.vue';
import { gmapApi } from 'vue2-google-maps';
export default {
  components: { HotelListComponent },
  name: 'map-component',
  data: () => ({
    loaded: false,
    options: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
      draggable: false,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
      ],
    },
    infoContent: '',
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35,
      },
    },
    hq: {
      name: {
        es: 'Asociación de Fabricantes de Muebles de Jalisco A.C.',
        en: 'Asociación de Fabricantes de Muebles de Jalisco A.C.',
      },
      address: 'Av. Mariano Otero 1313, Verde Valle, 44530 Guadalajara, Jal.',
      position: {
        lat: 20.656351,
        lng: -103.3889558,
      },
    },
    zones: [
      {
        value: 0,
        es: 'Todos los hoteles',
        en: 'All',
      },
      {
        value: 1,
        es: 'Alrededor de la expo',
        en: 'Around expo',
      },
      {
        value: 2,
        es: 'Caminando / 5 minutos coche',
        en: 'Walking distance / 5 minutes drive',
      },
      {
        value: 3,
        es: 'Coche 10 minutos en adelante',
        en: '10 minutes drive',
      },
      {
        value: 4,
        es: 'Zona centro',
        en: 'Downtown area',
      },
    ],
    hotels: [],
    filter: 0,
    filteredHotels: [],
    showMap: false,
  }),
  mounted() {
    if (localStorage.getItem('zone'))
      this.filter = JSON.parse(localStorage.getItem('zone'));
    this.hotels = hotels;
    this.filteredHotels = this.hotels;
    this.filteredHotels.push(this.hq);
  },
  created() {
    this.$gmapApiPromiseLazy({}).then(() => {
      this.loaded = true;
      this.fitToBound();
    });
  },
  methods: {
    /** https://github.com/xkjyeah/vue-google-maps/blob/no-deferred-ready/examples/infowindow.html */
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      let stars = '';
      for (let index = 1; index <= marker.stars; index++) {
        stars += '<i class="fas fa-star"></i>';
      }
      this.infoContent = `<h3>${marker.name[this.$i18n.locale]}</h3>
                          <p class="star mt-3">${stars}</p>
                          <p>${marker.address}</p>
      `;
      const message =
        this.$i18n.locale === 'es' ? 'Más información' : 'More information';
      this.infoContent += `<button class="v-btn button" onclick="document.getElementById('${marker.id}').scrollIntoView()" style="background-color: primary!important; padding: 5px 20px; color: #fff; margin-top: 1em">${message}</button>`;
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    fitToBound() {
      const bounds = new this.google.maps.LatLngBounds();
      this.filteredHotels.forEach((hotel) => {
        const bound = new this.google.maps.LatLng(
          hotel.position.lat,
          hotel.position.lng
        );
        bounds.extend(bound);
      });
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        map.setZoom(map.getZoom() - 1);
      });
    },
    toggleMap() {
      this.showMap = !this.showMap;
      setTimeout(() => {
        this.fitToBound();
      }, 50);
    },
    goto(id) {
      var element = document.getElementById(id);
      element?.scrollIntoView();
    },
  },
  computed: {
    zone() {
      return this.zones.filter((zone) => zone.value === this.filter);
    },
    showMapMessage() {
      let text = '';
      if (this.$i18n.locale === 'es' && this.showMap) text = 'Ocultar mapa';
      if (this.$i18n.locale === 'es' && !this.showMap) text = 'Mostrar mapa';
      if (this.$i18n.locale === 'en' && this.showMap) text = 'Hide map';
      if (this.$i18n.locale === 'en' && !this.showMap) text = 'Show map';
      return text;
    },
    google: gmapApi,
  },
  watch: {
    filter(val) {
      localStorage.setItem('zone', val);
      this.infoWinOpen = false;
      if (val === 0) {
        this.filteredHotels = this.hotels;
      } else {
        this.filteredHotels = this.hotels.filter(
          (hotel) => hotel.zone === parseInt(val)
        );
      }
      this.filteredHotels.push(this.hq);
      this.fitToBound();
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--primary-color);
}

h3.contact,
.contact a {
  color: var(--secondary-color) !important;
}
</style>
