<template>
  <div class="mt-4">
    <v-row>
      <v-col>
        <h2>{{ title }}</h2>
      </v-col>
    </v-row>
    <v-row v-for="(hotel, index) in filteredHoteles" :key="index">
      <v-col cols="12" :ref="hotel.id" :id="hotel.id">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-img
                  :src="getImgUrl(hotel.id, hotel.images[0])"
                  :aspect-ratio="1 / 1"
                  contain
                />
              </v-col>
              <v-col cols="12" md="9">
                <h2>{{ hotel.name[$i18n.locale] }}</h2>
                <p class="star mt-3">
                  <i
                    v-for="(star, index) in hotel.stars"
                    :key="index"
                    class="fas fa-star"
                  ></i>
                </p>
                <p class="mt-3">
                  {{ hotel.address }}
                </p>
                <p class="mt-3">{{ hotel.info[$i18n.locale] }}</p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ $t('hotel_list.table_headers.room') }}
                        </th>
                        <th class="text-center">
                          {{ $t('hotel_list.table_headers.breakfast') }}
                        </th>
                        <th class="text-center">
                          {{ $t('hotel_list.table_headers.price') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(room, index) in hotel.rooms[$i18n.locale]"
                        :key="index"
                      >
                        <td>{{ room.name }}</td>
                        <td>{{ room.breakfast }}</td>
                        <td>{{ room.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div>
                  {{ $t('hotel_list.rates') }}
                </div>
                <v-btn
                  class="my-auto mt-5"
                  color="primary"
                  dark
                  :to="`/reservar/${hotel.id}`"
                >
                  {{ $t('hotel_list.button') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'hotel-list-component',
  props: {
    hotels: Array,
    title: String,
  },
  methods: {
    getImgUrl(hotel, image) {
      return require(`@/assets/images/${hotel}/${image}`);
    },
  },
  computed: {
    filteredHoteles() {
      return this.hotels.filter((hotel) => hotel.rooms);
    },
  },
};
</script>
